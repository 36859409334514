import React from "react"
import LayoutComponent from "../components/Layout.js"


const FourZeroFour = () => (
  <LayoutComponent>
        <h1>Ups! Der er gået noget galt...</h1>
        <p>Du har navigeret dig ind på en side, der ikke eksisterer.</p>
        <p>Brug venligst menuen til at klikke dig ind på en af vores leverandørsider.</p>
    </LayoutComponent>
)
export default FourZeroFour